body{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: white;
  color: #444;
  overflow: auto!important;
  font-size: 11px;
  word-spacing: 2px;
}
a{
  color: #123ad5;
}
.logo-dashboard{
  width: 150px;
  margin-top: 5px;
}
.package-card{
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -60px;
}
.img-package-card{
  float: left;
  margin-right: 10px;
  margin-top: -5px;
}
.bg-grey{
  background: #f8f9f9;
}
.bg-white{
  background: white;
}
.z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-small, .btn-floating, .dropdown-content, .collapsible, .sidenav {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 15%), 0 1px 5px 0 rgb(0 0 0 / 0%);
}
.row{
  width: 100%;
}
.row .col{
  padding: 0.8rem;
}
.row-initial{
  width: initial!important;
}
.row-netral{
  width: -webkit-fill-available!important;
}
nav{
  background: white;
}
.navbar-fixed nav {
  position: fixed;
  max-width: 450px;
}
.navbar-fixed-50{
  height: 50px;
}
nav ul a{
  color: #383b3d;
  position: relative;
}
nav .brand-logo{
  padding: 10px;
}
nav .brand-logo img{
  height: 40px;
}
nav a.sidenav-trigger{
  display: block;
  margin: 0;
  padding-top: 10px;
}
nav .input-field {
  margin: 0;
  height: 40px;
  background-color: #f5f5f5;
  border-radius: 50px;
  margin: 10px;
  width: 180px;
}
nav .input-field input[type=search]:focus:not(.browser-default){
  background: #f5f5f5;
  border-radius: 50px;
}
nav .input-field i{
  height: 44px;
  line-height: 44px;
}
nav .input-field label i{
  color: #383b3d;
}
nav.nav-screen, .nav-screen, .nav-screen .nav-wrapper i, .nav-screen a.sidenav-trigger, .nav-screen a.sidenav-trigger i{
  height: 50px;
  line-height: 50px;
}
nav.nav-screen .brand-logo {
  padding: 0px 10px;
  font-size: 15px;
  font-weight: 500;
  color: #444;
  line-height: 50px;
}
nav .brand-logo {
  left: auto;
  transform: none;
}
.wellcome{
  position: absolute;
  top: -50px;
  color: white;
  border-radius: 15px;
  padding: 0 6px;
  color: #444;
  background: white;
  z-index: 1;
}
.wellcome-heading{
  position: absolute;
  top: -190px;
  padding: 0 6px;
  color: #fff;
  z-index: 1;
  width: -webkit-fill-available;
}
.wellcome-heading h2{
  margin-top: 0;
  font-size: 18px;
}
.carousel-product{
  height: 400px;
  width: auto;
  overflow: hidden;
}
.carousel-home{
  height: 180px!important;
  width: auto;
  overflow: hidden;
  position: relative;
}
.carousel-home1{
  height: 180px!important;
}
.carousel .carousel-item>img{
  height: auto;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  display: table;
}
.container{
  max-width: 450px;
  width: auto;
}
.m-top-10{
  margin-top: 10px;
}
.m-bottom-0{
  margin-bottom: 0;
}
.p-bottom-0{
  padding-bottom: 0px!important;
}
.p-top-10{
  padding-top: 10px;
}
.p-top-15{
  padding-top: 15px;
}
.title-card-show{
  font-size: 26px;
  font-weight: 500;
  position: relative;
  /* padding-left: 5px; */
}
.title-card-content{
  font-size: 16px;
  font-weight: 500;
  position: relative;
  /* padding-left: 5px; */
}
.title-view-more{
  font-size: 10px;
  font-weight: 400;
  /* padding-right: 5px; */
}
.title-card-price{
  font-size: 25px;
  font-weight: 500;
  color: #00c569;
}
.title-card-category{
  font-size: 11px;
  color: #827373;
}
.title-card-desc{
  font-size: 14px;
  color: #827373;
}
.title-card-sku{
  font-size: 11px;
  font-weight: bold;
}
.title-card-val{
  font-weight: 500;
}
.title-card-label{
  color: #827373;
}
.border-bottom{
  border-bottom: solid 1px #f5f5f5;
  margin-bottom: 0;
}
.text-right{
  text-align: right;
}
.card-product{
  padding: 0!important;
  box-shadow: none!important;
  background: none!important;
}
.card-product .card-content{
  padding: 10px!important;
  padding-top: 0!important;
}
.card.card-product.vertical .card-image{
  height: 165px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
}
.card{
  padding: 10px;
  border-radius: 2px;
  background-color: #f8f9f9;
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
  margin-bottom: 0;
  margin-top: 0;
  display: inline-block;
  width: 100%;
}
.card:hover{
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
}
.card .card-image img{
  /* margin:auto;
  width: 100%;
  height: auto; */
  top: -9999px;
  right: -9999px;
  left: -9999px;
  bottom: -9999px;
  margin: auto;
  position: absolute;
  width: auto;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: all 0.25s linear;
}
.card .card-content{
  padding: 0;
  padding-bottom: 5px;
  padding-top: 5px;
}
.card .title-card{
  font-size: 13px;
  line-height: 1.3;
  color: #444;
  height: 35px;
  display: inherit;
  font-weight: 500;
}
.card .category-card{
  font-size: 10px;
  line-height: 1;
  display: inline-block;
  margin-bottom: 7px;
  color: #6e6e6e;
}
.card .price-card{
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  color: #00c569;
}
.card .card-product{
  color: #444;
  font-weight: 500;
  margin-bottom: 5px;
  display: inline-block;
  width: 100%;
}
.card .card-product a{
  font-weight: 400;
}
.card .card-variant{
  margin-bottom: 5px;
  display: inline-block;
  width: 100%;
}
.padding-top0{
  padding-top: 0!important;
}
.back-grey{
  background: #f8f9f9;
}
.back-white{
  background: white;
}
.icon-apps{
  float: left;
  padding: 13px;
  text-align: center;
  cursor: pointer;
  width: 25%;
}
.icon-app i{
  font-size: 38px;
}
.icon-apps:hover .icon-app{
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  /* background: white;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
  border-radius: 5px; */
}
.icon-app-name{
  font-size: 11px;
}
.icon-app{
  border-radius: 50%;
  width: 65px;
  height: 65px;
  background: white;
  overflow: hidden;
  padding: 15px;
  margin: auto;
  margin-bottom: 5px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
}
.icon-app img{
  width: 100%;
  height: 100%;
}
.icon-categories .icon-image{
  text-align: center;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  background: white;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  margin-right: 5px;
  border: solid 1px white;
  border-radius: 10%;
}
.icon-categories{
  padding: 13px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 100px;
  margin-right: 5px;
}
.icon-categories:hover .icon-image{
  background: white;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
  border: solid 1px #ccc;
}
.icon-categories.active .icon-image{
  background: white;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
  border: solid 1px #ccc;
}
.icon-categories:hover .icon-category-name{
  font-weight: bold;
  color: black;
}
.icon-categories.active .icon-category-name{
  font-weight: bold;
  color: black;
}
.icon-categories i{
  color: #0f25d5;
  font-size: 40px; 
  padding-top: 12px;
}
.icon-category-name{
  font-size: 11px;
  color: #444;
  font-weight: 500;
}
.icon-image{
  border-radius: 100%;
  width: 70px;
  height: 70px;
  background: white;
  overflow: hidden;
  margin-bottom: 5px;
}
.icon-image img{
  width: 100%;
  height: 100%;
}
.full-width{
  width: 100%!important;
}
.login-form{
  padding: 20px;
  background: #123ad5;
  min-height: 100vh;
  margin-bottom: 0;
}
.login-form img{
  margin: auto;
  display: table;
}
.span-white{
  color: #ccc;
}
.span-white a{
  color: white;
}
.datepicker-field .input-field{
  width: 100%;
  padding: 0;
}
.screen-loading{
  background: #0f25d5;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  max-width: 450px;
  top: 0;
}
.screen-loading .load-icon{
  position: absolute;
  margin: auto;
  display: table;
  top: 40%;
  width: 100%;
}
.autocomplete-field input{
  border: 0!important;
  margin-bottom: 0;
}
.jZkBQk > .wrapper{
  border: 1px solid #e5dddd!important;
  z-index: 2!important;
  min-height: 52px;
}
.autocomplete-field input:not([type]):focus:not([readonly]), .autocomplete-field input[type=text]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=password]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=email]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=url]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=time]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=date]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=datetime]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=datetime-local]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=tel]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=number]:not(.browser-default):focus:not([readonly]), .autocomplete-field input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]){
  border: 0;
  box-shadow: none;
}
.full-address-box{
  background: #f5f5f5;
  padding: 2px 10px;
  border-radius: 5px;
}
.btn-right-label{
  float: right;
  font-size: 10px;
  top: -15px;
  right: 0;
  position: absolute;
  padding-right: 5px;
  color: #847c7c;
}
.btn-right-label2 {
  float: right;
  font-size: 10px;
  right: 0;
  position: absolute;
  padding-right: 10px;
  color: #847c7c;
}
.btn-right-label .material-icons, .btn-right-label2 .material-icons{
  font-size: 11px;
}
.input-field.col label{
  width: 100%;
}
.avatar-user{
  overflow: hidden;
  margin: auto;
  display: table;
}
.load-more-btn{
  width: 100%;
  display: block;
  background: #f8f9f9;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  font-size: 11px;
  cursor: pointer;
  font-weight: 400;
}
.load-more-btn-white{
  width: 100%;
  display: block;
  background:white;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  font-size: 11px;
  cursor: pointer;
  font-weight: 400;
  margin-top: 10px;
  border-bottom: 2px solid #f1f1f1;
}
.search-field {
  position: absolute;
  width: 100%;
  margin-top: -8px;
}
.search-field .close-btn-search{
  height: auto;
  line-height: normal;
  background: white;
  color: #444;
  padding: 5px 10px;
  cursor: pointer;
  text-align: right;
  color: orange;
  position: absolute;
  right: 0;

}
.search-field li{
  width: 100%;
  height: auto;
  line-height: normal;
  background: white;
  color: #444;
  padding: 5px 10px;
  cursor: pointer;
}
.search-field li:hover{
  background: #f5f5f5;
}
.pb5{
  padding-bottom: 5px!important;
}
.add-chart-seaction{
  position: relative;
  height: 36px;
}
.btn-confirm{
  width: 95%!important;
  display: table;
  margin: auto;
  border-radius: 30px!important;
  margin-bottom: 10px;
}
.add-chart-seaction .add-chart-seaction-box{
  position: fixed;
  max-width: 450px;
  bottom: 10px;
  border-radius: 30px;
  overflow: hidden;
  width: 95%;
  left: 2%;
  right: 2%;
  display: table;
}
.add-chart-seaction .btn{
  width: 100%;
  border-radius: 0;
  font-size: 14px;
  text-transform: initial;
}
.add-chart-seaction .col{
  padding: 0;
}
.on-total{
  text-align: center;
  font-weight: bold;
  padding: 5px;
  font-size: 16px;
}
.sub-order-total{
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.sub-order-total .total{
  font-size: 20px;
  font-weight: bold;
}
.sub-order-total .status-payment{
  font-size: 11px;
  color: #9e9e9e;
}
.btn-chart{
  background: red;
}
.btn-chart:hover{
  background: red;
  opacity: 0.7;
}
.btn-chat{
  background-color: #26a69a;
}
.btn-chat:hover{
  opacity: 0.7;
}
.btn-default{
  background: #f5f5f5!important;
  color: #444;
  border: solid 1px #f5f5f5;
  box-shadow: none;
}
.btn-default:hover, .btn-default:focus{
  background: none;
  box-shadow: none;
}
.btn-delete{
  color: white;
  background: #444!important;
  border: 0;
  width: 100%;
  margin-bottom: 5px;
  font-size: 10px;
  height: auto;
  line-height: inherit;
  padding: 5px;
}
.btn-delete:hover, .btn-delete:focus{
  opacity: 0.8;
  background: #444;
}
.widget-box-chart{
  position: fixed;
  z-index: 1;
  background: rgba(0,0,0,0.6);
  width: 100%;
  height: 100vh;
  top: 0;
}
.widget-box-chart .btn{
  width: 100%;
}
.widget-box-chart .closeBtn{
  cursor: pointer;
  position: absolute;
  right: 10px;
  color: red;
  z-index: 2;
  padding: 1px 5px;
  background: white;
  font-size: 15px;
  top: 5px;
}
.modal-widget-box-chart{
  position: fixed;
  bottom: 0px;
  background: white;
  box-shadow: 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 11px 15px -7px rgb(0 0 0 / 20%);
  border-radius: 5px;
  z-index: 999;
  max-width: 350px;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  margin-bottom: 10px;
}
.card.horizontal {
  display: flex;
  border-radius: 0;
  box-shadow: none;
}
.card.horizontal .card-image {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-right: 10px;
  background: #ccc;
}
.card.vertical .card-image{
  position: relative;
  height: 140px;
  overflow: hidden;
  margin-bottom: 10px;
  background:#fff;
}
.sel{
  display: block!important;
}
.select-none-ui input.select-dropdown{
  display: none!important;
}
.select-none-ui svg{
  display: none!important;
}
.input-radius{
  border-radius: 50px!important;
}
.input-border{
  background-color: rgba(255,255,255,0.9);
  width: 100%;
  border: 1px solid #f2f2f2!important;
  padding: 5px!important;
  padding-left: 10px!important;
  position: relative;
  box-sizing: border-box!important;
  height: 3rem;
  font-size: 14px!important;
  box-shadow: none;
}
.input-group input{
  display: table-cell;
  width: 60%!important;
  height: 40px!important;
  text-align: center;
}
.input-group button{
  width: 20%!important;
  position: relative;
  height: 40px!important;
  vertical-align: initial;
}
.input-group.small input{
  display: table-cell;
  width: 60%!important;
  height: 30px!important;
  text-align: center;
  font-size: small!important;
}
.input-group.small button{
  width: 20%!important;
  position: relative;
  height: 30px!important;
  vertical-align: initial;
  padding: 0;
  line-height: 0;
}
.icon-shop-count{
  background: red;
  line-height: 10px;
  position: absolute;
  top: 25px;
  color: white;
  padding: 1px 3px;
  font-size: 8px;
}
.onBtnCart{
  cursor: pointer;
}
.error-card{
  color: red;
  font-size: 10px;
  padding: 10px;
  text-align: center;
  border: solid 1px #ccc;
  margin-bottom: 5px;
}
#__react-alert__ div{
  top: auto!important;
  bottom: 20%!important;
}
.card-cart{
  border-bottom: solid 1px #f5f5f5;
  border-left: 0;
  border-right: 0;
  background: none;
}
.card-cart a{
  color: #444;
}
.hr-top{
  border-top: dashed 1px #ede6e6;
  border-bottom: 0;
  margin-top: 0;
}
#modal1{
  height: 100vh;
  max-height: 100%!important;
  width: 100%;
}
.padding0{
  padding: 0!important;
}
.p-top-0{
  padding-top: 0!important;
}
.p-bottom0{
  padding-bottom: 0!important;
}
.p-top7{
  padding-top: 7px!important;
}
.p-top0{
  padding-top: 0!important;
}
.m-bottom0{
  margin-bottom: 0;
}
.m-bottom30{
  margin-bottom: 30px;
}
.m-top0{
  margin-top: 0!important;
}
.m-top20{
  margin-top: 20px!important;
}
.label{
  background: #f5f5f5;
  padding: 2px 10px;
}
.label-default{
  border: solid 1px #f1e5e5;
}
.label-sku{
  background: white;
  border: solid 1px #ccc;
  margin-right: 5px;
  font-size: 10px;
  padding: 2px;
  text-align: center;
}
.span-orange{
  color:#d28c0d;
}
.span-red{
  color: red;
}
.span-blue{
  color: rgb(83, 70, 207);
}
.span-green{
  color: #26a69a;
}
.span-grey{
  color:#847c7c;
  text-transform:capitalize;
}
[type="checkbox"]+span:not(.lever),
input:not([type]), input[type=text]:not(.browser-default), 
input[type=password]:not(.browser-default), 
input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), 
input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), 
input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), 
input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), 
input[type=search]:not(.browser-default), textarea.materialize-textarea
{
  font-size: 12px;
  height: 2rem;
}
.select{
  display: block;
}
.select-label{
  position: relative!important;
  font-size: 11px!important;
  width: 100%!important;
  float: left;
  left: 0!important;
  margin-bottom: 20px;
}
.list-bank-number{
  font-size: 11px;
}
.box-bank-number{
  text-align: center;
  border: solid 3px #f5f5f5;
  margin: 20px;
  padding: 10px;
  border-radius: 5px;
}
.border-none{
  border: 0;
}
.label-basic{
  background: #ccc;
  padding: 5px;
  margin-right: 10px;
  color: #444;
  font-weight: 400;
  font-size: 8px;
  text-transform: uppercase;
}
.input-search-form{
  margin: 6px!important;
  height: 38px!important;
}
.material-icon-search{
  height: 40px!important;
  line-height: 40px!important;
}
.acumulation{
  background: #fff;
  padding: 10px;
  color: #444;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
}
.acumulation .acumulation-total{
  font-size: 16px;
}
.acumulation small{
  font-size: 10px;
}
.box-order-new{
  border-radius: 4px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  background: white;
}
.btn, .btn-large, .btn-small{
  background-color: #0f25d5;
}
.btn:hover, .btn-large:hover, .btn-small:hover{
  background-color: #0f25d5;
  opacity: 1;
}
.sidenav{
  width: 250px;
  background-color: #0f25d5;
  color: white;
}
.sidenav i{
  margin-right: 10px!important;
  font-size: 20px;
}
.sidenav li{
  padding: 0 15px;
}
.sidenav li>a{
  padding: 0 15px;
  font-size: 15px;
  font-weight: normal;
  color: white;
}
.sidenav li i{
  color: white!important;
}
.sidenav-profile{
  text-align: center;
  margin-bottom: 20px;
}
.sidenav-profile div{
  height: 20px;
}
.sidenav-profile .avatar{
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: auto;
  border-radius: 100%;
  margin-top: 20px;
}
.sidenav-profile .avatar img{
  width: 100%;
}
.profile-info-table {
  background-color: #fff;
}
.profile-info-table .profile-info-block {
  display: flex;
  align-items: center;
  padding: 15px 15px;
  color: #444;
  /* border-bottom: 2px solid #f1f1f1; */
}
.profile-info-table .profile-info-block__title {
  font-weight: 400;
  flex-basis: 50%;
}
.profile-info-table .profile-info-block__value {
  font-size: 11px;
  font-weight: 300;
  flex-basis: 50%;
  text-align: right;
}
.box-profile-info{
  padding-top: 20px;
}
.box-profile-info .avatar{
  width: 130px;
  height: 130px;
  overflow: hidden;
  border-radius: 50%;
  background: #ccc;
  margin: auto;
}
.box-profile-info .avatar img{
  width: 100%;
}
.box-profile-info .full-name{
  font-size: 18px;
}
.box-profile-info .id-card{
  font-size: 12px;
}
.box-profile-info .id-card small{
  color: #444;
  color: 10px;
}
.more-opts{
  padding-top: 0;
  color: #444;
  float: right;
}
.bottom-sheet{
  position: relative;
  height: 75px;
}
.bottom-sheet ul{
  position: fixed;
  z-index: 99;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: space-between;
  max-width: 450px;
  padding: 0;
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%);
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
  width: 95%;
}
.bottom-sheet ul li i{
  color: #0f25d5;
}
.bottom-sheet ul li{
  display: flex;
  width: 25%;
}
.bottom-sheet ul li:hover a{
  padding: 10px;
  opacity: 0.9;
  background: #f5f5f5;
}
.bottom-sheet ul li a{
  background-color: #fff;
  padding: 10px;
  text-align: center;
  color: #444;
  font-size: 10px;
  width: 100%;
  position: relative;
}
.bottom-sheet ul li a.active {
  padding: 10px;
  opacity: 0.9;
  background: #f5f5f5;
}
input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #ccc;
  box-shadow: none;
}
input.valid:not([type]), input.valid:not([type]):focus, input.valid[type=text]:not(.browser-default), input.valid[type=text]:not(.browser-default):focus, input.valid[type=password]:not(.browser-default), input.valid[type=password]:not(.browser-default):focus, input.valid[type=email]:not(.browser-default), input.valid[type=email]:not(.browser-default):focus, input.valid[type=url]:not(.browser-default), input.valid[type=url]:not(.browser-default):focus, input.valid[type=time]:not(.browser-default), input.valid[type=time]:not(.browser-default):focus, input.valid[type=date]:not(.browser-default), input.valid[type=date]:not(.browser-default):focus, input.valid[type=datetime]:not(.browser-default), input.valid[type=datetime]:not(.browser-default):focus, input.valid[type=datetime-local]:not(.browser-default), input.valid[type=datetime-local]:not(.browser-default):focus, input.valid[type=tel]:not(.browser-default), input.valid[type=tel]:not(.browser-default):focus, input.valid[type=number]:not(.browser-default), input.valid[type=number]:not(.browser-default):focus, input.valid[type=search]:not(.browser-default), input.valid[type=search]:not(.browser-default):focus, textarea.materialize-textarea.valid, textarea.materialize-textarea.valid:focus, .select-wrapper.valid>input.select-dropdown {
  border-bottom: 1px solid #ccc;
  box-shadow: none;
}
label.active{
  color: #9e9e9e!important;
}
.tab-nav ul::-webkit-scrollbar {
  display: none;
}
.tab-nav ul{
  overflow: auto;
  white-space: nowrap;
  max-width: 100%;
}
.tab-nav ul li{
  border-bottom: solid 4px white;
  padding: 10px 14px;
  font-size: 11px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.tab-nav ul li:hover{
  border-bottom: solid 4px #f5f5f5;
}
.tab-nav ul li.active{
  border-bottom: solid 4px #f5f5f5;
  font-weight: bold;
}
.not-found{
  text-align: center;
  padding: 20px;
  color: #444;
}
.box-sidebar-filter{
  position: relative;
}
.sidebar-filter{
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%);
}
.sidebar-filter-head{
  padding: 10px;
  border-bottom: solid 2px #f5f5f5;
  color: #444;
  font-weight: 400;
  font-size: 13px;
}
.sidebar-filter-body{
  padding-top: 10px;
}
.sidebar-filter-seaction{
  margin-bottom: 0;
}
.label-filter{
  margin-bottom: 5px;
  color: #444;
}
.sidebar-filter-price input{
  background: #f2f2f2!important;
  padding-left: 5px;
}
.btn-search-sidebar1, .btn-search-sidebar2{
  text-transform: capitalize;
  font-size: 10px;
  margin-bottom: 15px;
  width: 100%;
}
.btn-search-sidebar2{
  background-color: #0f25d5;
  color: white;
}
.sidebar-filter-close{
  float: right;
  border: solid 1px #f5f5f5;
  font-size: 11px;
  padding: 2px 10px;
  cursor: pointer;
}
.input-field .prefix.active{
  color: #0f25d5;
}
.input-field-customize{
  padding: 3px 5px!important;
}
.discount-price{
  color: #aaa;
  text-decoration: line-through #aaa;
  font-size: 11px;
}
.discount-label{
  position: absolute;
  background: #f10731;
  padding: 2px 5px;
  z-index: 1;
  color: white;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  border-radius: 50%;
  left: 10px;
  top: 10px;
}
.discount-label-big{
  color: white;
  font-weight: 500;
  right: 0;
  font-size: 11px;
  background: #444;
  float: right;
  padding: 5px;
  border-radius: 5px;
}
.off-disc{
  color: white;
  font-size: 8px;
}
.point-label {
  position: absolute;
  background: #fbe00d;
  padding: 2px 5px;
  z-index: 1;
  color: black;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  top: 48px;
  left: 10px;
  background: #f5bb52;
  border-radius: 10%;
}
.label-point{
  font-size: 10px;
  color: #0f25d5;
  padding: 2px 5px;
}
.label-cashback{
  font-size: 10px;
  color: green;
  padding: 2px 5px;
}
.btn-chat{
  background: green;
}
.carousel .carousel-item.active{
  visibility: inherit;
}
.bg-col-acumulation{
  padding-left: 15px!important;
  padding-right: 15px!important;
  position: relative;
}
.bg-acumulation-container{
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}
.bg-acumulation-home{
  width: 100%;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}
.bg-acumulation-col{
  background: white;
  width: 100%;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  padding: 5px 10px;
}
.acumulation-input-top{
  background: white;
  border: 0!important;
  border-radius: 50%!important;
  background: white;
  border: 0!important;
  border-radius: 10%!important;
  padding: 0!important;
  height: 35px;
  margin-top: -20px;
}
.acumulation-input{
  color: #444;
  border: solid 1px #ccc;
  box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
  /* background: #f5f5f5; */
  border-radius: 5px;
  padding-top: 2px;
  text-align: center;
}
.acumulation-input i{
  font-size: 35px;
  color:#0f25d5;
}
.acumulation-home-total{
  color: #444;
  font-size: 17px;
}
.acumulation-home .small{
  color: #444;
  font-size: 10px;
}
.acumulation-home-total i{
  font-size: 18px;
  position: relative;
  top: 3px;
  color: #0f25d5;
}
.p8{
  padding: 8px!important;
}
.text-center{
  text-align: center;
}
table tr{
  font-size: 10px;
}
.align-items-center{
  text-align: center;
}
.avatar.rounded-circle{
  width: 50px;
  height: 50px;
  float: left;
  overflow: hidden;
}
.avatar.rounded-circle img{
  width: 50px;
}
.form-edit{
  padding: 15px;
  margin-top: 40px;
}
.margin-none{
  margin:0;
}
.list-content{
  display: flex;
  padding: 10px;
  color:#444;
  background: white;
  border-bottom: 2px solid #f1f1f1;
  position: relative;
  border-radius: 5px;
}
.list-content .avatar{
  margin-right: 10px;
}
.list-content .list-content-text{
  padding-top: 5px;
}
.list-content .list-content-title{
  font-size: 13px;
}
.datepicker-ipt input{
  background: white!important;
  height: 38px!important;
  border: 1px solid #e5dddd!important;
  padding: 0 10px!important;
  box-sizing: inherit!important;
}
.datepicker-ipt .input-field {
  padding: 0;
  width: 100%;
  margin: 0;
}
.modal-visible{
  display: block!important;
  z-index: 999;
}
.input-back, .datepicker{
  background-color: rgba(255,255,255,0.9)!important;
  width: 100%;
  border: 1px solid #e5dddd!important;
  border-radius: 2px;
  height: 3rem!important;
  padding-left: 10px!important;
  padding-right: 10px!important;
  box-sizing: inherit!important;
}
select, .datepicker{
  border: 1px solid #e5dddd!important;
  background: white;
}
.m-bottom10 {
  margin-bottom: 8px;
  display: inline-block;
}
.modal-header{
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  padding: 15px;
  border-bottom: solid 1px #ebe5e6;
}
.btn-large {
  height: auto;
  line-height: 55px;
  font-size: 15px;
  padding: 0 18px;
  border-radius: 8px!important;
}
.position-revert{
  position: revert!important;
}
.datepicker-field .input-field{
  margin: 0!important;
}
.modal{
  max-width: 450px;
}
.modal-close{
  position: absolute;
  right: 10px;
  top: 12px;
  color: #4e2424;
}
.pointer{
  cursor: pointer;
}
.search-on-list{
  width: 100%;
  margin-bottom: 10px;
}
.search-on-list input{
  padding-left: 40px!important;
  border-radius: 25px!important;
}
.label-success{
  background: green;
  color: white;
  border-radius: 2px;
}
.label-default{
  background: white;
  border-radius: 2px;
}
.blank-carousel{
  background: #0f25d5;
}
.blank-message{
  padding: 10px;
  text-align: center;
  background:white;
}
.btn{
  border-radius: 20px;
}
.block-regular{
  width: 100%;
  background: #1824d5;
  height: 150px;
  text-align: center;
  padding-top: 50px;
}
.block-title{
  font-size: 25px;
  color: white;
}
.no-radius{
  border-radius: 0!important;
}
.no-border{
  border:0!important;
}
.avatar-icon{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.scroll-touch{
  overflow-x: scroll;
  overflow-y: hidden;
  /* white-space: nowrap; */
  padding-bottom: 12px;
}
.scroll-touch::-webkit-scrollbar {
  display: none;
}
.loader-logo{
  width: 80px;
  height: auto;
  margin-bottom: 10px;
}
.login-logo{
  width: 200px;
}
.h1-login{
  text-align: center;
  font-size: 30px;
  color: #000;
}
.btn-download{
  font-size: 10px;
  padding: 5px 10px;
  height: auto;
  line-height: normal;
  margin-top: 5px;
}
.icon-act-content{
  width: 30px;
  float: right;
  align-self: flex-end;
  position: absolute;
  right: 0;
  top: 0;
}
button:focus {
  outline: none;
  background-color: darkgray!important;
}
.outstock-label{
  background: red;
  color: white;
  padding: 5px 4px;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;
  left: 0;
  margin: auto;
  width: 80px;
  font-size: 10px;
  text-align: center;
}
.col-product{
  width: 150px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.countdown{
  position: absolute;
  background: black;
  color: white;
  padding: 2px 5px;
  font-size: 15px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100px;
  text-align: center;
  margin-top: 5px;
}
.countdown2{
  position: absolute;
  background: black;
  color: white;
  padding: 2px 5px;
  font-size: 15px;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100px;
  text-align: center;
  margin-top: 5px;
}
.countdown-txt {
  position: relative;
  color: black;
  font-weight: bold;
  font-size: 15px;
}
.free-shipping{
  position: absolute;
  background: #1824d5;
  padding: 2px 3px;
  z-index: 1;
  color: white;
  font-weight: 500;
  font-size: 8px;
  text-align: center;
  left: 0;
  right: auto;
  background: #1824d5;
  width: 75px;
}
.cashback{
  position: absolute;
  background: #1824d5;
  padding: 2px 5px;
  z-index: 1;
  color: #fbf8f8;
  font-weight: 500;
  font-size: 8px;
  text-align: center;
  left: 0;
  right: auto;
  background: #3ec66a;
  left: 75px;
}
.scroll-container{
  display: inline-flex;
  width: auto;
}
.active_fix{
  transform: translateY(-14px) scale(0.8)!important;
}
.input-cell{
  width: 100%!important;
  display: table-cell;
  height: 40px!important;
  text-align: center;
}
.icon-social{
  color: white;
  display: inline-block;
  margin: 5px;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 15px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
}
.icon-social.fb{
  background-color: #4467af;
}
.icon-social.ig{
  background-color: #fff;
  color: black!important;
}
.icon-social.yt{
  background-color: #f40000;
}
.icon-social.tl{
  background-color: #3396de;
}
.icon-social.web{
  background-color: #ccc;
  color: black!important;
}
.a-device{
  padding-bottom: 4px;
  padding-top: 4px;
  color: #444;
  display: inline-block;
}
.box-contact-cs{
  background: white;
  padding: 0 10px;
  border-radius: 8px;
}
.btn-chat-am{
  font-size: 11px;
  padding: 0 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-top: 8px;
}
.avatar-am{
  border-radius: 100px;
  height: 60px;
  width: 60px;
  padding-top: 10px;
  margin: 0;
  display: table;
}
.carousel .indicators{
  bottom: 10px;
}
.love-whistlist{
  float: right;
}
.love-whistlist i{
  color: red;
  cursor: pointer;
  padding: 5px 5px 5px 15px;
}
.love-whistlist i.active{
  color: red
}
.love-whistlist-catalog{
  float: right;
  bottom: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}
.input-new{
  border: solid 1px #ccc;
  border-radius: 8px;
  overflow: hidden;
  background: white;
}
.input-new input{
  margin-bottom: 0!important;
  border: 0!important;
}
.box-col{
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}
.bottom-fixed{
  position: absolute;
  width: 100%;
  padding: 10px;
  bottom: 20px;
}
.bottom-fixed .submit{
  border-radius: 50px;
}
.height-full-screen{
  height: 100vh;
}
.pb-10{
  padding-bottom: 0!important;
}
.reff{
  border: dashed 2px #ccc;
}
.btn-wa{
  background: green;
  color: white;
  border: 0;
  border-radius: 10px;
  font-size: 11px;
}
.input-field .prefix{
  top: 0.8rem;
}
.idcard-package{
  width: 100%;
  height: 600px;
}
.idcard-package-avatar{
  position: absolute;
  top: 225px;
  z-index: 999;
  left: -25px;
  right: 0;
}
.idcard-package-package {
  font-size: 14px;
  position: absolute;
  left: 0;
  right: 0;
  top: 420px;
}
.idcard-company-logo{
  left: 0;
  right: 0;
  position: absolute;
  top: 100px;
  margin: auto;
}
.idcard-company-logo img{
  width: 230px;
  margin: auto;
  display: block;
}
.no-verified{
  background: white;
  text-align: center;
  padding: 10px;
  position: absolute;
  max-width: 450px;
  width: 100%;
  border: dashed 1px #ccc;
  margin-bottom: 10px;
}

.no-verified a{
  font-weight: bold;
}
.copy{
  background: #ccc;
  border: solid 1px #ccc;
  height: 20px;
  margin-left: 10px;
}
.eye{
  color: #ccc;
  right: 0;
  cursor: pointer;
}
.label-level-type{
  font-size: 16px;
  color: orange;
}